*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  background-color: #efefef;
}
.modal-wrapper{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.is-error {
  font-size: 12px;
  color: red;
  padding-top: 2px;
}

.modal-backdrop{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-box{
  position: relative;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-height: 30%;
  width: 60%;
  overflow-y:auto ;
  background-color: white;
  box-sizing: 0 0 10px rgba(0,0,0,0.25);
  z-index: 101;
  padding: 40px;
}
.px-1,
.p-1 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-1,
.p-1 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.box-shadow-y {
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

.menu-container {
  position: fixed;
  top: 8%;
  bottom: 0;
  margin-top: auto;   
  min-width: 300px;
  z-index: 10;
  background-color: transparent;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.menu-container.active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.icon {
  height: 30px;
  width: 30px;
  vertical-align: baseline;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.toggler {
  margin-left: 0;
  cursor: pointer;
}

@media (min-width: 769px) {
  .menu-container {
    width: 25%;
    min-width: auto;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@media (min-width: 1024px) {
  .menu-container {
    width: 16.6667%;
    min-width: auto;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}